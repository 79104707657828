import { FC } from 'react';

import { Layout as AntLayout } from 'antd';

import BackTop from '../components/BackTop/BackTop';
import BasicList from '../components/BasicList/BasicList';
import BasicListItem from '../components/BasicList/BasicListItem';
import BodyText from '../components/BodyText/BodyText';
import ContactMe from '../components/ContactMe/ContactMe';
import Divider from '../components/Divider/Divider';
import Footer from '../components/Footer/Footer';
import NumberedList from '../components/NumberedList/NumberedList';
import NumberedListItem from '../components/NumberedList/NumberedListItem';
import PageContainer from '../components/PageContainer/PageContainer';
import SEO from '../components/SEO/SEO';
import TitleWithLine from '../components/TitleWithLine/TitleWithLine';
import Layout from '../templates/Layout/Layout';

const RegulaminPage: FC = () => {
  return (
    <Layout>
      <SEO
        title="Tomasz Dyła - Regulamin"
        desc="Szukasz biura do wynajęcia w Warszawie? Pomogę Ci znaleźć biuro dopasowane do Twoich potrzeb w atrakcyjnej cenie."
      />
      <Divider variant="large" />
      <AntLayout.Content>
        <PageContainer>
          <TitleWithLine>Regulamin i Polityka prywatności</TitleWithLine>
          <TitleWithLine level={3} rightLine={false}>
            Regulamin
          </TitleWithLine>
          <NumberedList>
            <NumberedListItem>
              Definicje: <br /> Pojęcia obecne w regulaminie oznaczają:
              <BasicList>
                <BasicListItem>
                  Ustawa o świadczeniu usług drogą elektroniczną – ustawa z 18 lipca 2002 r. o świadczeniu usług drogą
                  elektroniczną.
                </BasicListItem>
                <BasicListItem>
                  RODO – rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w sprawie
                  ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
                  takich danych oraz uchylenia dyrektywy 95/46/WE.
                </BasicListItem>
                <BasicListItem>
                  Serwis – serwis internetowy udostępniany pod adresem www.vertigoproperty.pl
                </BasicListItem>
                <BasicListItem>
                  Administrator – właściciel serwisu, tj. spółka pod firmą Vertigo Property Group spółka jawna sp. j. z
                  siedzibą w Warszawie (00-511), przy ul. Nowogrodzkiej 10, wpisana do rejestru przedsiębiorców
                  Krajowego Rejestru Sądowego pod numerem KRS 0000441164, REGON 146692967, NIP 5252435817, której akta
                  rejestrowe prowadzi Sąd Rejonowy dla miasta stołecznego Warszawy w Warszawie, XII Wydział Gospodarczy
                  Krajowego Rejestru Sądowego
                </BasicListItem>
                <BasicListItem>
                  Użytkownik – osoba korzystająca z serwisu, niebędąca konsumentem w rozumieniu art. 22 Kodeksu
                  Cywilnego
                </BasicListItem>
              </BasicList>
            </NumberedListItem>
            <NumberedListItem>
              Niniejszy regulamin określa zasady korzystania z usług serwisu www.vertigoproperty.pl
            </NumberedListItem>
            <NumberedListItem>
              Korzystanie z portalu vertigoproperty.pl jest bezpłatne i nie jest uzależnione od spełnienia żadnych
              warunków.
            </NumberedListItem>
            <NumberedListItem>
              Użytkownicy zobowiązani są do korzystania z serwisu zgodnie z przepisami prawa polskiego, niniejszym
              regulaminem oraz zgodnie z dobrymi obyczajami i poszanowaniem praw i interesów osób trzecich.
            </NumberedListItem>
            <NumberedListItem>
              Usługi świadczone przez Administratora za pośrednictwem serwisu polegają na dostarczeniu jego użytkownikom
              ogólnych informacji na temat rynku nieruchomości komercyjnych – w szczególności rynku powierzchni
              biurowych w Warszawie.
            </NumberedListItem>
            <NumberedListItem>
              Ogłoszenia publikowane w serwisie vertigoproperty.pl nie stanowią oferty w rozumieniu przepisów Kodeksu
              Cywilnego.
            </NumberedListItem>
            <NumberedListItem>
              Po wyrażeniu zgody przez Użytkownika Administrator może przesyłać informację handlową na podany przez
              Użytkownika adres e-mail. Użytkownik ma prawo w każdym momencie odwołać zgodę.
            </NumberedListItem>
            <NumberedListItem>
              Administrator nie ponosi odpowiedzialności za
              <BasicList>
                <BasicListItem>
                  działania i decyzje podjęte przez Użytkownika w związku z wykorzystaniem informacji zamieszczonych w
                  Serwisie.
                </BasicListItem>
                <BasicListItem>
                  ewentualny brak dostępu do jego zasobów i może w każdej chwili zawiesić jego działalność bez
                  konieczności informowania o tym jego Użytkowników.
                </BasicListItem>
              </BasicList>
            </NumberedListItem>
            <NumberedListItem>
              Serwis korzysta z Google Analytics. Usługa polega na analizy wyświetleń serwisów internetowych
              udostępnianych przez Google. Do właściwej analizy sposobu wykorzystania serwisu przez Użytkownika Google
              Analytics korzysta z plików cookies. Informacje gromadzone przez pliki cookies będą przekazywane spółce
              Google.
            </NumberedListItem>
            <NumberedListItem>
              Wypełniając formularz zamieszczony na portalu, Użytkownik dobrowolnie udostępnia swoje dane osobowe oraz
              wyraża zgodę na ich gromadzenie oraz przetwarzanie stosownie do wymogów ustawy z dn. 29 sierpnia 1997 r. o
              ochronie danych osobowych oraz na otrzymywanie drogą elektroniczną informacji handlowych zgodnie z Ustawą
              z dn. 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną.
            </NumberedListItem>
            <NumberedListItem>
              Właściciel portalu podczas realizacji usług może korzystać z pomocy współpracujących z nim podmiotów
              gospodarczych, którzy uprawnieni będą do bezpośredniego kontaktu z Użytkownikiem.
            </NumberedListItem>
            <NumberedListItem>
              Wszelkie uwagi, pytania oraz reklamacje prosimy o kierowanie na adres kontakt@vertigogroup.pl
            </NumberedListItem>
            <NumberedListItem>
              Właściciel portalu zastrzega sobie prawo do zmiany niniejszego regulaminu.
            </NumberedListItem>
          </NumberedList>
          <TitleWithLine level={3} rightLine={false}>
            Polityka prywatności
          </TitleWithLine>
          <BodyText>
            Zgodnie z art. 13 ust. 1 i ust. 2 rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia
            2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego
            przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (RODO), informujemy, że:
          </BodyText>
          <NumberedList>
            <NumberedListItem>
              Administratorem Pani/Pana danych osobowych jest spółka pod firmą Vertigo Property Group Spółka jawna sp.
              j. z siedzibą w Warszawie (00-511), przy ul. Nowogrodzkiej 10, wpisana do rejestru przedsiębiorców
              Krajowego Rejestru Sądowego pod numerem KRS 0000441164, NIP 5252435817, REGON 146692967, której akta
              rejestrowe prowadzi Sąd Rejonowy dla miasta stołecznego Warszawy w Warszawie, XII Wydział Gospodarczy
              Krajowego Rejestru Sądowego.
            </NumberedListItem>
            <NumberedListItem>
              Pani/Pana dane osobowe będą przetwarzane w celu prowadzenia działalności gospodarczej i świadczenia usług
              zleconych Administratorowi.
            </NumberedListItem>
            <NumberedListItem>
              Pani/Pana dane osobowe będą przechowywane przez okres nie dłuższy, niż jest to konieczne do osiągnięcia
              celów przetwarzania.
            </NumberedListItem>
            <NumberedListItem>
              Ma Pani/Pan prawo dostępu do treści swoich danych osobowych, do cofnięcia zgody na przetwarzanie Pani/Pana
              danych osobowych, prawo do ograniczenia ich przetwarzania, prawo do ich sprostowania, przenoszenia oraz
              usunięcia.
            </NumberedListItem>
            <NumberedListItem>
              Posiada Pani/Panu prawo wniesienia skargi do organu nadzorczego, jeśli, Pani/Pana zdaniem, dane osobowe są
              przetwarzane w sposób niezgodny z przepisami RODO.
            </NumberedListItem>
            <NumberedListItem>
              Podanie przez Panią/Pana danych osobowych jest konieczne, aby Vertigo Property Group spółka jawna sp. j.
              mogła się z Panią/Panem skontaktować. Podanie danych osobowych jest dobrowolne, jednak odmowa ich podania
              uniemożliwia kontakt ze strony Vertigo Property Group spółka jawna sp.j.
            </NumberedListItem>
            <NumberedListItem>
              Odbiorcami Pani/Pana danych osobowych będą podmioty gospodarcze współpracujące z Vertigo Property Group
              Spółka jawna sp. j.
            </NumberedListItem>
            <NumberedListItem>
              Pani/Pana dane osobowe nie będą przetwarzane w sposób zautomatyzowany i nie będą profilowane.
            </NumberedListItem>
          </NumberedList>
        </PageContainer>
      </AntLayout.Content>
      <ContactMe />
      <Footer />
      <BackTop />
    </Layout>
  );
};

export default RegulaminPage;
