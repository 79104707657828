import styled from '@emotion/styled';

export const StylesNumberedList = styled.ol`
  list-style-type: decimal;
  list-style-position: outside;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const StylesNumberedListItem = styled.li`
  margin-left: 21px;
  padding: 3px 0;

  &::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
  }
`;
